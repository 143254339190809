import React, { FC } from "react";
import { RouteComponentProps, NavLink, useHistory } from "react-router-dom";
import { Routes } from "../../AppRoutes";
import { Icon } from "../../components";
import { useSelector } from "../../store";
import { EUserRole } from "../../types";

export interface IConfigWraperProps extends RouteComponentProps {}

export const withConfigWraper = (Component: FC<IConfigWraperProps>) => (props: IConfigWraperProps) => {
  const user = useSelector((state) => state.user);
  const history = useHistory();
  if ([EUserRole.SUPER_ADMIN, EUserRole.ADMIN].includes(user?.userRole) === false) {
    history.replace(Routes.dashboard.path);
    return null;
  }

  return (
    <div className="withConfigWraper">
      <div className="TabLinks">
        <NavLink to={Routes.withdrawConfig.path} exact={true}>
          <Icon.Setting />
          Withdraw Config
        </NavLink>

        <NavLink to={Routes.ChangePassword.path} exact={true}>
          <Icon.Setting />
          Change Password
        </NavLink>

        {user.userRole === "SUPER_ADMIN" && (
          <NavLink to={Routes.activities.path} exact={true}>
            <Icon.Setting />
            Activities
          </NavLink>
        )}

        <NavLink to={Routes.maintenanceConfig.path} exact={true}>
          <Icon.Setting />
          Maintenance Config
        </NavLink>

        <NavLink to={Routes.rolePermission.path} exact={true}>
          <Icon.Setting />
          Role Permission
        </NavLink>
        
        <NavLink to={Routes.Announcement.path} exact={true}>
          <Icon.Setting />
          Announcement
        </NavLink>

        <NavLink to={Routes.dteConfig.path} exact={true}>
          <Icon.Setting />
          DTE Config
        </NavLink>

        {user.userRole === "SUPER_ADMIN" && (
          <NavLink to={Routes.Leverage.path} exact={true}>
            <Icon.Setting />
            Leverage
          </NavLink>
        )}

        {user.userRole === "SUPER_ADMIN" && (
          <NavLink to={Routes.ChangeWallet.path} exact={true}>
            <Icon.Setting />
            Change Wallet
          </NavLink>
        )}

        {user.userRole === "SUPER_ADMIN" && (
          <NavLink to={Routes.MetConversionConfig.path} exact={true}>
            <Icon.Setting />
            MET Conversion Config
          </NavLink>
        )}

        {user.userRole === "SUPER_ADMIN" && (
          <NavLink to={Routes.MecMiningConfig.path} exact={true}>
            <Icon.Setting />
            MEC Mining Config
          </NavLink>
        )}

        {user.userRole === "SUPER_ADMIN" && (
          <NavLink to={Routes.bridgeSystemV2Config.path} exact={true}>
            <Icon.Setting />
            Bridge System V2
          </NavLink>
        )}
      </div>

      <Component {...props} />
    </div>
  );
};
