import React, { Fragment, useEffect, useState } from "react";
import XLSX from "xlsx";
import { TableFilterInputText, TableFilterRangeTimeInput } from "../../../components/table-filter-inputs";
import { Button, CreateAlert, NumberUtils, Table } from "../../../modules";
import { AdminService } from "../../../services/admin";
import moment from "moment";
import { TradeLayout } from "../component";
import { useSelector } from "../../../store";
import { getEnv } from "../../../configs";

export const bridgeSystemV2 = TradeLayout(() => {

    const coins = useSelector((state) => state.coins);
    
    const [data, setData] = useState<any>();

    let priceStructure = [
        {
            name: 'BTC Bridge',
            key: 'BTCUSDT',
        },
        {
            name: 'ETH Bridge',
            key: 'ETHUSDT',
        },
        {
            name: 'GOLD Bridge',
            key: 'GOLDUSD',
        },
        {
            name: 'OIL Bridge',
            key: 'OILUSD',
        },
    ];

    let orderStructure = [
        {
            name: "Product",
            key: "symbolName",
        },
        {
            name: "Exchange",
            key: "exchange",
        },
        {
            name: "Order Type",
            key: "option",
        },
        {
            name: "Open Price",
            key: "openPrice",
            render: (item:any) => (item?.openPrice != null) ? new Intl.NumberFormat('en-US').format(item?.openPrice) : 'N/A',
        },
        {
            name: "Open Time",
            key: "created",
            render: (item:any) => moment(item.created).format("L HH:mm:ss")
        },
        {
            name: "Close Price",
            key: "closePrice",
            render: (item:any) => (item?.closePrice != null) ? new Intl.NumberFormat('en-US').format(item?.closePrice) : 'N/A',
        },
        {
            name: "Close Time",
            key: "modified",
            render: (item:any) => moment(item.modified).format("L HH:mm:ss")
        },
        {
            name: "Order Volume",
            key: "amount",
            render: (item:any) => (item?.amount != null) ? new Intl.NumberFormat('en-US').format(item?.amount) : 'N/A',
        },
        {
            name: "Leverage",
            key: "leverage",
            render: (item:any) => (item?.leverage != null) ? new Intl.NumberFormat('en-US').format(item?.leverage) : 'N/A',
        },
        {
            name: "Profit/Loss",
            key: "status",
        },
    ];

    const exchanges = Array.from(new Set(data?.map((item:any) => item?.exchange)));

    useEffect(() => {
        AdminService.getBridgeSystemV2({}).then((res) => {
            setData(res?.result);
        });
    }, []);

    return (
        <div className="bridge-system-v2-page">
            <div className="bridge-system-v2__price container-fluid">
                <div className="row">
                    {priceStructure?.map((item:any, idx:number) => (
                        <div key={idx} className="col">
                            <div className="bridge-system-v2__price__header">{item?.name}</div>
                            {data?.filter((x:any) => x.symbol === item.key).map((element:any, idx:number) => (
                                <div className="bridge-system-v2__price__item">
                                    <div className="bridge-system-v2__price__item__label">{element?.exchange}</div>
                                    <div className="bridge-system-v2__price__item__value">{(element?.price != null) ? new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2}).format(element?.price) : 'N/A'}</div>
                                </div>
                            ))}
                        </div>
                    ))}
                </div>
            </div>
            <div className="bridge-system-v2__order">
                <div style={{fontSize: '20px', fontWeight: '700', margin: '40px 0 12px'}}>Transactions</div>
                <Table
                    hasOrderColumn
                    hasSearchButton
                    filters={[
                        {
                            name: "Date",
                            key: "created",
                            input: (e) => (
                                <TableFilterRangeTimeInput
                                    {...e}
                                    fromKey="fromDate"
                                    toKey="toDate"
                                />
                            ),
                            defaultValue: {
                                fromDate: moment().startOf("month"), 
                                toDate: moment().endOf("day")
                            },
                        },
                    ]}
                    structure={orderStructure}
                    fetchData={async (state) => {
                        return AdminService.getOrderBridgeSystemV2List({
                            ...state,
                            page: state.pageNumber,
                            pageSize: state.limit,
                        }).then((res) => {
                            return {
                                count: res?.result?.total,
                                data: res?.result?.orders,
                            };
                        });
                    }}
                />
            </div>
        </div>
    );
});
